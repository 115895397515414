@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --clr-accent-600: #0b1e81;
  --clr-accent-500: rgb(22, 30, 77);
  --clr-accent-400: hsl(230.4, 45.9%, 42.7%);
  --clr-accent-300: rgb(88, 111, 226);
  --clr-accent-100: hsl(216, 100%, 96%);

  --clr-primary-400: hsl(228, 39%, 23%);

  --clr-neutral-900: hsl(232, 12%, 13%);
  --clr-neutral-100: hsl(0 0% 100%);

  --ff-primary: "Be Vietnam Pro", sans-serif;

  --ff-body: var(--ff-primary);
  --ff-heading: var(--ff-primary);

  --fw-regular: 400;
  --fw-semi-bold: 500;
  --fw-bold: 700;

  --fs-300: 0.8125rem;
  --fs-400: 0.875rem;
  --fs-500: 0.9375rem;
  --fs-600: 1rem;
  --fs-625: 1.25rem;
  --fs-650: 1.4rem;
  --fs-700: 1.875rem;
  --fs-800: 2.5rem;
  --fs-900: 3.5rem;

  --fs-body: var(--fs-400);
  --fs-primary-heading: var(--fs-800);
  --fs-secondary-heading: var(--fs-700);
  --fs-sub-heading: var(--fs-625);
  --fs-nav: var(--fs-500);
  --fs-button: var(--fs-300);

  --size-100: 0.25rem;
  --size-200: 0.5rem;
  --size-300: 0.75rem;
  --size-400: 1rem;
  --size-500: 1.5rem;
  --size-600: 2rem;
  --size-700: 3rem;
  --size-800: 4rem;
  --size-900: 5rem;
}

@media (min-width: 50em) {
  :root {
    --fs-body: var(--fs-500);
    --fs-primary-heading: var(--fs-900);
    --fs-secondary-heading: var(--fs-800);
    --fs-nav: var(--fs-300);
  }
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
* {
  margin: 0;
  padding: 0;
  font: inherit;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* main{
  background-color: var(--clr-neutral-100);
} */

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture,
svg {
  max-width: 100%;
  display: block;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* Utility classes */

.text-primary-400 {
  color: var(--clr-primary-400);
}

.text-accent-400 {
  color: var(--clr-accent-400);
}

.text-accent-100 {
  color: var(--clr-accent-100);
}

.text-neutral-100 {
  color: var(--clr-neutral-100);
}

.text-neutral-900 {
  color: var(--clr-neutral-900);
}

.bg-primary-400 {
  background-color: var(--clr-primary-400);
}

.bg-accent-400 {
  background-color: var(--clr-accent-400);
}

.bg-accent-100 {
  background-color: var(--clr-accent-100);
}

.bg-neutral-100 {
  background-color: var(--clr-neutral-100);
}

.bg-neutral-900 {
  background-color: var(--clr-neutral-900);
}

.fw-bold {
  font-weight: var(--fw-bold);
}

.fw-semi-bold {
  font-weight: var(--fw-semi-bold);
}

.fw-regular {
  font-weight: var(--fw-regular);
}

.fs-primary-heading {
  font-size: var(--fs-primary-heading);
  line-height: 1.1;
}

.fs-secondary-heading {
  font-size: var(--fs-secondary-heading);
  line-height: 1.1;
}

.fs-sub-heading {
  font-size: var(--fs-sub-heading);
  line-height: 1.1;
}

.fs-300 {
  font-size: var(--fs-300);
}

.fs-400 {
  font-size: var(--fs-400);
}

.fs-500 {
  font-size: var(--fs-500);
}

.fs-600 {
  font-size: var(--fs-600);
}

.padding-block-900 {
  padding-block: var(--size-900);
}

.padding-block-700 {
  padding-block: var(--size-700);
}

.padding-block-600 {
  padding-block: var(--size-600);
}

.padding-block-500 {
  padding-block: var(--size-500);
}
/* 
.container {
  --max-width: 1440px;
  --padding: 2.5rem;

  width: min(var(--max-width), 100% - (var(--padding) * 2));
  margin-inline: auto;
}

.services .container,
.about-us .container {
  --max-width: 740px;
  --padding: 0.5rem;
  width: min(var(--max-width), 100% - (var(--padding) * 2));
  margin-inline: auto;
}
*/

.flow * + * {
  margin-top: var(--flow-space, 2em);
}

.even-columns {
  display: flex;
  flex-direction: row;
}

@media (min-width: 60em) {
  .even-columns {
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
}

/* General Styling */
body {
  font-size: var(--fs-body);
  font-family: var(--ff-body);
  color: black;
  overflow-x: hidden;
}

.main-wrapper {
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}

/* button */

.button {
  cursor: pointer;
  border: 0;
  border-radius: 100vmax;
  padding: 1em 2.5em;
  font-weight: var(--fw-bold);
  font-size: var(--fs-button);
  line-height: 1;
  color: var(--clr-neutral-100);
  background-color: var(--clr-accent-400);
  box-shadow: 0 1.125em 1em -1em var(--clr-accent-500);
}

.button[data-type="inverted"] {
  background-color: var(--clr-neutral-100);
  color: var(--clr-accent-400);
}

.button:hover,
.button:focus-visible {
  background-color: var(--clr-accent-300);
}

.button[data-type="inverted"]:hover,
.button[data-type="inverted"]:focus-visible {
  background-color: var(--clr-neutral-100);
  color: var(--clr-accent-300);
}

/* Navigation */
/* 
.primary-header {
  margin-top: var(--size-700);
  box-shadow: 0px 30px 40px 0px rgba(219, 218, 218, 0.1);
}

.nav-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-nav-toggle {
  display: none;
}

@media (max-width:50em) {
  .primary-navigation {
    display: none;
  }

  .mobile-nav-toggle {
    display: block;
    cursor: pointer;
    background: transparent;
    border: 0;
    padding: 0.5em;
  }

  .mobile-nav-toggle .icon-close {
    display: none;
  }
}
 */

/* .primary-header {
  margin-top: var(--size-700);
  box-shadow: 0px 30px 40px 0px rgba(219, 218, 218, 0.1);
  padding: 0 5em 0 5em;
} */

nav {
  display: flex;
  justify-content: space-around;
  min-height: 7vh;
  width: 100%;
  align-items: center;
  box-shadow: 0 30px 40px 0 hsl(0deg 0% 51% / 10%);
  padding: 3em 0 3em 0;
}

.logo a {
  cursor: auto;
}

.navlinks {
  display: flex;
  list-style: none;
  justify-content: space-around;
  width: 40em;
  gap: var(--size-600);
  font-size: var(--fs-600);
  font-weight: var(--fw-semi-bold);
}

.link {
  cursor: pointer;
}

.link:hover {
  color: var(--clr-accent-500);
  font-weight: bold;
}

.link a {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.link a:hover {
  color: var(--clr-accent-500);
  font-weight: bold;
}

.hamburger-icon {
  cursor: pointer;
  color: var(--clr-accent-400);
  font-size: 1.5em !important;
  display: none;
}

.logo {
  display: flex;
}

@media (max-width: 60em) {
  .even-columns {
    display: flex;
    flex-direction: column;
  }

  /* All */
  .bg-depth1 {
    position: absolute;
    margin-left: 7em !important;
  }

  .headline {
    padding-block: 3em !important;
  }

  /* #headline .container,
  #contact-us .container {
    max-width: 100em;
    margin-left: auto;
    margin-right: auto;
  } */

  /* Headline */
  .small-primary-heading {
    margin-right: 0em !important;
  }

  /* Navigation */

  .hamburger-icon {
    display: block;
  }

  .navlinks {
    z-index: 2;
    position: absolute;
    right: 0px;
    display: flex;
    flex-direction: column;
    height: 80vh;
    width: 100%;
    align-items: center;
    top: 14vh;
    background-color: white;
    font-size: var(--fs-600);
    font-weight: var(--fw-semi-bold);
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transition: transform 0.5s ease-in;
    -webkit-transition: transform 0.5s ease-in;
    box-shadow: 0px -3px 3px 0px rgba(171, 171, 171, 0.56);
  }

  .active {
    transform: translateX(0%);
    -webkit-transform: translate(0%);
  }

  /* Headline */
  .headline-column-1 {
    margin-top: 0em !important;
    margin-bottom: 4em;
  }

  /* Services */

  .box-outer-border {
    box-shadow: 0px 10px 25px 0px rgba(94, 94, 94, 0.1) !important;
  }

  /* Contact Us */
  #contact-us .even-columns {
    justify-content: center;
    display: flex;
    flex-direction: column !important;
  }

  .contact-us-column {
    margin-bottom: 2em;
    margin-left: auto;
    margin-right: auto;
  }
  iframe .googlemap {
    width: 100%;
    height: 250px;
  }
}

.visually-hidden {
  position: absolute;
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

/* Headline */

.headline-text {
  max-width: 48em;
}

.headline-column-1 {
  margin-top: 6em;
  margin-right: auto;
  margin-left: auto;
  max-width: 48em;
}

.small-primary-heading {
  font-size: var(--fs-500);
  float: right;
  margin-right: 4em;
  margin-top: 0 !important;
}

.fs-primary-heading::after {
  content: "";
  display: block;
  width: 2em;
  max-width: 70%;
  border-bottom: 0.15em solid #0b1e81;
  margin: 0.1em 0 0;
}

.header-logo {
  width: 15em;
  height: 3em;
}

.heading h2::after {
  content: "";
  display: block;
  width: 3em;
  max-width: 70%;
  border-bottom: 0.2em solid #0b1e81;
  margin: 0.5em auto 0;
}

/* .center {
  text-align: center;
} */

.blue {
  color: var(--clr-accent-600);
}

/* Services */

.services ol {
  font-size: var(--fs-500);
  font-weight: var(--fw-semi-bold);
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5em;
}

.services-icon {
  justify-content: center;
  font-size: 4em;
  display: flex;
  color: var(--clr-accent-600);
}

.box-outer-border {
  box-shadow: 0px 30px 45px 0px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;
}

.box-inner-spacing {
  padding: 3em 3em 3em 3em;
  display: flex;
  flex-direction: column;
}

/* .googlemap {
  width: 450;
  height: 350;
} */

/* Contact Us */

#contact-us .even-columns {
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.contact-us-column {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  transition: all 0.5s;
}

.fs-secondary-heading::after {
  content: "";
  display: block;
  width: 2em;
  max-width: 70%;
  border-bottom: 0.15em solid #0b1e81;
  margin: 0.1em 0 0;
}

.contact-us-details {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.googlemap {
  max-width: 450px;
  max-height: 400px;
}

.info-icons {
  font-size: 2em;
  padding-right: 0.5em;
}

.contact-us .info-details {
  margin-top: 0;
}

.info-details a {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.info-details :hover {
  color: #0b1e81;
  font-weight: bold;
}

.info-details a:hover {
  color: #0b1e81;
  font-weight: bold;
}

footer {
  background-color: var(--clr-accent-600);
  text-align: center;
  font-size: 0.8em;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 1000ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.bg-depth1 {
  position: absolute;
  margin-left: 30em;
  z-index: -1;
}

.bg-depth2 {
  position: absolute;
  margin-left: 40em;
  margin-bottom: 10em;
  z-index: -1;
}

.bg-depth3 {
  position: absolute;
  bottom: 10em;
  right: 15em;
  bottom: 5em;
  z-index: -1;
}

.bg-depth4 {
  position: absolute;
  bottom: 20em;
  left: 40em;
  z-index: -1;
}

.chatbox {
  display: none;
}

.chatbox-show {
  display: block !important;
  right: 0;
  position: fixed;
  z-index: 1;
}

.chat-container {
  position: fixed;
  right: 0;
  margin-right: 2.5em;
  z-index: 1;
  bottom: 0;
  margin-bottom: 2.5em;
}

.reposition-chatbox {
  top: 24em;
}

.quit-icon-reposition {
  position: fixed;
  right: 0;
  margin-top: 6px;
  margin-right: 1em;
  z-index: 2;
}

.chat-icon {
  font-size: 4rem;
  cursor: pointer;
  color: black;
}
.chat-icon:hover {
  color: #455bc9;
}

.close-icon {
  border-radius: 0.2rem;
  font-size: 2.5rem;
  cursor: pointer;
  color: #eb5252;
  box-shadow: 0px 0px 25px -1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 25px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 25px -1px rgba(0, 0, 0, 0.75);
}

.close-icon:hover {
  background-color: white;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.parallax-container {
  height: 100vh;
  /* overflow: hidden; */
}

.loadingOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #e8e8e8;
}

.shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}

.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}

.socket {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 50%;
  margin-left: -100px;
  top: 50%;
  margin-top: -100px;
}

.hex-brick {
  background: #000000;
  width: 30px;
  height: 17px;
  position: absolute;
  top: 5px;
  animation-name: fade00;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  -webkit-animation-name: fade00;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
}

.h2 {
  transform: rotate(60deg);
  -webkit-transform: rotate(60deg);
}

.h3 {
  transform: rotate(-60deg);
  -webkit-transform: rotate(-60deg);
}

.gel {
  height: 30px;
  width: 30px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.center-gel {
  margin-left: -15px;
  margin-top: -15px;
  animation-name: pulse00;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  -webkit-animation-name: pulse00;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
}

.c1 {
  margin-left: -47px;
  margin-top: -15px;
}

.c2 {
  margin-left: -31px;
  margin-top: -43px;
}

.c3 {
  margin-left: 1px;
  margin-top: -43px;
}

.c4 {
  margin-left: 17px;
  margin-top: -15px;
}

.c5 {
  margin-left: -31px;
  margin-top: 13px;
}

.c6 {
  margin-left: 1px;
  margin-top: 13px;
}

.c7 {
  margin-left: -63px;
  margin-top: -43px;
}

.c8 {
  margin-left: 33px;
  margin-top: -43px;
}

.c9 {
  margin-left: -15px;
  margin-top: 41px;
}

.c10 {
  margin-left: -63px;
  margin-top: 13px;
}

.c11 {
  margin-left: 33px;
  margin-top: 13px;
}

.c12 {
  margin-left: -15px;
  margin-top: -71px;
}

.c13 {
  margin-left: -47px;
  margin-top: -71px;
}

.c14 {
  margin-left: 17px;
  margin-top: -71px;
}

.c15 {
  margin-left: -47px;
  margin-top: 41px;
}

.c16 {
  margin-left: 17px;
  margin-top: 41px;
}

.c17 {
  margin-left: -79px;
  margin-top: -15px;
}

.c18 {
  margin-left: 49px;
  margin-top: -15px;
}

.c19 {
  margin-left: -63px;
  margin-top: -99px;
}

.c20 {
  margin-left: 33px;
  margin-top: -99px;
}

.c21 {
  margin-left: 1px;
  margin-top: -99px;
}

.c22 {
  margin-left: -31px;
  margin-top: -99px;
}

.c23 {
  margin-left: -63px;
  margin-top: 69px;
}

.c24 {
  margin-left: 33px;
  margin-top: 69px;
}

.c25 {
  margin-left: 1px;
  margin-top: 69px;
}

.c26 {
  margin-left: -31px;
  margin-top: 69px;
}

.c27 {
  margin-left: -79px;
  margin-top: -15px;
}

.c28 {
  margin-left: -95px;
  margin-top: -43px;
}

.c29 {
  margin-left: -95px;
  margin-top: 13px;
}

.c30 {
  margin-left: 49px;
  margin-top: 41px;
}

.c31 {
  margin-left: -79px;
  margin-top: -71px;
}

.c32 {
  margin-left: -111px;
  margin-top: -15px;
}

.c33 {
  margin-left: 65px;
  margin-top: -43px;
}

.c34 {
  margin-left: 65px;
  margin-top: 13px;
}

.c35 {
  margin-left: -79px;
  margin-top: 41px;
}

.c36 {
  margin-left: 49px;
  margin-top: -71px;
}

.c37 {
  margin-left: 81px;
  margin-top: -15px;
}

.r1 {
  animation-name: pulse00;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.2s;
  -webkit-animation-name: pulse00;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 0.2s;
}

.r2 {
  animation-name: pulse00;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.4s;
  -webkit-animation-name: pulse00;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 0.4s;
}

.r3 {
  animation-name: pulse00;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.6s;
  -webkit-animation-name: pulse00;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 0.6s;
}

.r1 > .hex-brick {
  animation-name: fade00;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.2s;
  -webkit-animation-name: fade00;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 0.2s;
}

.r2 > .hex-brick {
  animation-name: fade00;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.4s;
  -webkit-animation-name: fade00;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 0.4s;
}

.r3 > .hex-brick {
  animation-name: fade00;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.6s;
  -webkit-animation-name: fade00;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 0.6s;
}

@keyframes pulse00 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.01);
    transform: scale(0.01);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes fade00 {
  0% {
    background: #252525;
  }

  50% {
    background: #000000;
  }

  100% {
    background: #353535;
  }
}
